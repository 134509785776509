jQuery(function () {
	isotopeInit();
	APP.init();

	// init
	lozadInit();
	fancyboxInit();

	// twentytwenty
	// jQuery(".twentytwenty-container[data-orientation!='vertical']").twentytwenty({
	// 	default_offset_pct: 0.5,
	// });
});


jQuery(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = jQuery("header"),
	body = jQuery("body"),
	backdrop = jQuery(".backdrop"),
	backToTop = jQuery(".back-to-top"),
	buttonMenu = jQuery("#buttonMenu"),
	mobileWrap = jQuery(".mobile-wrap"),
	buttonSearch = jQuery("header .button-search"),
	searchWrap = jQuery(".search-wrap"),
	heightHeader = jQuery("header").height(),
	heightWindow = jQuery(window).height(),
	widthWindow = jQuery(window).width(),
	outerHeightWindow = jQuery(window).outerHeight();

const calcVW = (size) => {
	console.log(size / 1920 * 100 * 16);
	return size / 1920 * 100 * 16;
};

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		jQuery(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		jQuery(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			jQuery(this).removeClass('open');
			buttonMenu.removeClass('open'); // remove button menu header
			mobileWrap.removeClass('open'); // remove mobile wrap
			buttonSearch.removeClass('open'); // remove button search header
			searchWrap.removeClass('open'); //
			body.removeClass('overflow-hidden');
			jQuery('.filter-list').removeClass('active'); // remove filter list
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			jQuery('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		const mainMenu = jQuery("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "appendTo",
			desktopWrapper: "header .header-right .button-group",
			desktopMethod: "insertBefore",
			breakpoint: 1279.98,
		});
	},
	toggleDropdown: () => {
		const dropdown = jQuery(".dropdown"),
			dropdownToggle = jQuery(".dropdown-toggle"),
			dropdownMenu = jQuery(".dropdown-menu");
		dropdownToggle.on("click", function () {
			jQuery(this).toggleClass("active");
			jQuery(this).parents(dropdown).find(dropdownMenu).toggleClass("open");
		});
	},
	toggleMenu: () => {
		buttonMenu.on("click", function () {
			jQuery(this).toggleClass("open");
			mobileWrap.toggleClass('open');
			buttonSearch.removeClass('open');
			searchWrap.removeClass('open');
			if (buttonMenu.hasClass('open')) {
				backdrop.addClass('open');
				body.addClass('overflow-hidden');
			} else {
				backdrop.toggleClass('open');
				body.toggleClass('overflow-hidden');
			}
		});
	},
	toggleSearch: () => {
		buttonSearch.on("click", function () {
			jQuery(this).toggleClass("open");
			searchWrap.toggleClass("open");
			buttonMenu.removeClass('open');
			mobileWrap.removeClass('open');
			if (buttonSearch.hasClass('open')) {
				backdrop.addClass('open');
				body.addClass('overflow-hidden');
			} else {
				backdrop.toggleClass('open');
				body.toggleClass('overflow-hidden');
			}
		});
	},
	setBackground: () => {
		jQuery("[setBackground]").each(function () {
			jQuery(this).css(
				"background-image",
				"url(" + jQuery(this).attr("setBackground") + ")"
			);
		});
	},
	sourceInfo: () => {
		console.log(
			"%cNAT",
			"font-size:100px;color:#000;text-shadow:0 1px 0 #151515,0 2px 0 #151515 ,0 3px 0 #151515 ,0 4px 0 #151515 ,0 5px 0 #151515 ,0 6px 1px rgba(0,0,0,.1),0 0 5px rgba(0,0,0,.1),0 1px 3px rgba(0,0,0,.3),0 3px 5px rgba(0,0,0,.2),0 5px 10px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.2),0 20px 20px rgba(0,0,0,.15);"
		);
		console.log(
			"%c NAT %c - NAT in Viet Nam",
			"border-radius: 2px; padding: 3px; background: #000; color: #ffffff",
			"color: #000"
		);
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		APP.toggleDropdown();
		APP.toggleMenu();
		APP.toggleSearch();
		// APP.setBackground();
		APP.sourceInfo();
	}
}

// const homeBanner = new Swiper('.home-banner .swiper', {
// 	loop: true,
// 	autoplay: {
// 		delay: 5000,
// 		disableOnInteraction: false,
// 	},
// 	pagination: {
// 		el: '.home-banner .swiper-pagination',
// 	},
// 	effect: 'fade',
// 	fadeEffect: {
// 		crossFade: true
// 	},
// 	// Navigation arrows
// 	navigation: {
// 		nextEl: '.home-banner .button-next',
// 		prevEl: '.home-banner .button-prev',
// 	},
// });

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		// enableAutoReload: true,
	})

	// Picture observer
	// with default `load` method
	const pictureObserver = lozad('.lozad-picture', {
		threshold: 0.1
	})

	// Background observer
	// with default `load` method
	const backgroundObserver = lozad('.lozad-background', {
		threshold: 0.1
	})

	observer.observe()
	pictureObserver.observe()
	backgroundObserver.observe()
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	jQuery(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function isotopeInit() {
	// init Isotope
	var $gridActive = $(".gallery-filter-tab li.active").attr('data-filter');

	var $grid = $('.gallery-list').isotope({
		layoutMode: 'packery',
		itemSelector: '.gallery-item',
		packery: {
			gutter: 30
		},
	});

	$grid.isotope({ filter: $gridActive })

	// bind filter button click
	$('#filters').on('click', 'li', function () {
		var filterValue = $(this).attr('data-filter');
		$grid.isotope({ filter: filterValue });
	});

	// change is-checked class on buttons
	$('.gallery-filter-tab').each(function (i, buttonGroup) {
		var $buttonGroup = $(buttonGroup);
		$buttonGroup.on('click', 'li', function () {
			$buttonGroup.find('.active').removeClass('active');
			$(this).addClass('active');
		});
	});
}
